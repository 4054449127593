import React, { useState, useEffect, useContext } from "react";

import PriceContext from "./price-context";
import { useHttpClient } from "../hooks/http-hook";
import fContext from "./f-context";

const PriceContextProvider: React.FC = (props) => {
  const [priceList, setPriceList] = useState<Array<any> | null>(null);
  const [galleryRegularPrice, setGallerRegularPrice] = useState<Number>(0);
  const [galleryCollectionPrice, setGalleryCollectionPrice] =
    useState<Number>(0);
  const [shippingPrice, setShippingPrice] = useState<Number>(6);
  const [expressPrice, setExpressPrice] = useState<Number>(12);
  const [finalRegularPrice, setFinalRegularPrice] = useState<Number>(0);
  const [finalCollectionPrice, setFinalCollectionPrice] = useState<Number>(0);
  const [priceDim, setPriceDim] = useState<any>(null);
  const { sendRequest } = useHttpClient();
  const fCtx = useContext(fContext);

  const populatePriceList = (price_list: Array<object>) => {
    setPriceList(price_list);
  };

  const fetchPricesSecond = async () => {
    try {
      //replace hardcoded eur by context variable
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "api/shop/prices_eur"
      );
      return responseData.prices;
    } catch (err) {}
  };

  const getPricePerDim = (dim: Number) => {
    //assume list is ordered from smaller dim_cm to higher
    if (priceList === null || priceList.length < 1) {
      fetchPricesSecond();
      if (priceList === null || priceList.length < 1) {
        return {};
      } else {
        for (var j = 0; j < priceList.length; j++) {
          if (dim <= priceList[j].dim_cm) {
            return priceList[j];
          }
        }
        return { base_price: -1 };
      }
    } else {
      for (var k = 0; k < priceList.length; k++) {
        if (dim <= priceList[k].dim_cm) {
          return priceList[k];
        }
      }
      return { base_price: -1 };
    }
  };

  const getFinalPricing = (
    dim: Number,
    art_type: string,
    collection_moulding: boolean,
    mount: string,
    mat_shape: string,
    mat_top?: number,
    mat_bottom?: number,
    mat_right?: number,
    mat_left?: number
  ) => {
    //var priceList = getPricePerDim(dim);
    var priceList = getPricePerDim(dim);
    var final_price = 0;

    if (priceList) {
      if (priceList.base_price === -1) {
        final_price = -1;
      } else {
        final_price = priceList.base_price;
        if (art_type.includes("studio") || art_type === "digital_photo") {
          final_price = priceList.studio_price;
        }
        if (mount === "floating" || mount === "box") {
          final_price += priceList.mount_special;
        }
        if (mat_shape === "italiano" || mat_shape === "oversized") {
          final_price += priceList.pp_special;
        }
        if (
          mat_shape === "custom" &&
          mat_top &&
          mat_bottom &&
          mat_right &&
          mat_left &&
          mat_top + mat_bottom + mat_right + mat_left > 20
        ) {
          final_price += priceList.pp_special;
        }
        if (art_type === "unmounted_canvas_studio") {
          final_price += priceList.canvas_mount;
        }
        if (collection_moulding) {
          final_price += priceList.collection_moulding;
        }
      }
    } else {
    }

    return final_price;
  };

  const calculatePrice = (priceDimList: any) => {
    setShippingPrice(priceDimList.shipping);
    setExpressPrice(priceDimList.express);

    if (priceDimList.base_price === -1) {
      setGallerRegularPrice(-1);
      setGalleryCollectionPrice(-1);
      setFinalRegularPrice(-1);
      setFinalCollectionPrice(-1);
    } else {
      var final_price = priceDimList.base_price;

      if (fCtx.artType.includes("studio") || fCtx.artType === "digital_photo") {
        final_price = priceDimList.studio_price;
      }
      if (fCtx.artType === "unmounted_canvas_studio") {
        final_price += priceDimList.canvas_mount;
      }
      setGallerRegularPrice(final_price);
      setGalleryCollectionPrice(final_price + priceDimList.collection_moulding);

      if (fCtx.mount === "floating" || fCtx.mount === "box") {
        final_price += priceDimList.mount_special;
      }
      if (fCtx.matShape === "italiano" || fCtx.matShape === "oversized") {
        final_price += priceDimList.pp_special;
      }
      if (
        fCtx.matShape === "custom" &&
        +fCtx.matTop + +fCtx.matBottom + +fCtx.matRight + +fCtx.matLeft > 20
      ) {
        final_price += priceDimList.pp_special;
      }
      setFinalRegularPrice(final_price);
      setFinalCollectionPrice(final_price + priceDimList.collection_moulding);
    }
  };

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        //replace hardcoded eur by context variable
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "api/shop/prices_eur"
        );
        populatePriceList(responseData.prices);
      } catch (err) {}
    };
    fetchPrices();
  }, [sendRequest]);

  useEffect(() => {
    const priceRange = async () => {
      if (fCtx.wSelected && fCtx.hSelected) {
        var dim = Number(fCtx.wSelected) + Number(fCtx.hSelected);

        //assume list is ordered from smaller dim_cm to higher
        if (priceList === null || priceList.length < 1) {
          fetchPricesSecond();
          if (priceList === null || priceList.length < 1) {
            setPriceDim({});
          } else {
            for (var j = 0; j < priceList.length; j++) {
              if (dim <= priceList[j].dim_cm) {
                setPriceDim(priceList[j]);
                calculatePrice(priceList[j]);
                return true;
              }
            }
            setPriceDim({ base_price: -1 });
            return true;
          }
        } else {
          for (var k = 0; k < priceList.length; k++) {
            if (dim <= priceList[k].dim_cm) {
              setPriceDim(priceList[k]);
              calculatePrice(priceList[k]);
              return true;
            }
          }
          setPriceDim({ base_price: -1 });
          return true;
        }
      }
    };
    priceRange();
  }, [fCtx.wSelected, fCtx.hSelected, priceList]);

  useEffect(() => {
    const finalPrice = async () => {
      if (priceDim) {
        calculatePrice(priceDim);
      }
    };
    finalPrice();
  }, [
    fCtx.artType,
    fCtx.mount,
    fCtx.matShape,
    fCtx.matTop,
    fCtx.matBottom,
    fCtx.matRight,
    fCtx.matLeft,
    priceDim,
  ]);

  return (
    <PriceContext.Provider
      value={{
        priceList,
        galleryCollectionPrice,
        galleryRegularPrice,
        finalRegularPrice,
        finalCollectionPrice,
        shippingPrice,
        expressPrice,
        populatePriceList,
        getPricePerDim,
        getFinalPricing,
      }}
    >
      {props.children}
    </PriceContext.Provider>
  );
};

export default PriceContextProvider;
