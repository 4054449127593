import { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import i18n from "../../i18nextConf";

const scriptId = 'widget_edesk';

export function WidgetEdesk() {
    const location = useLocation();
    const locale = i18n.language === "fr" ? "fr" : "en";

    useEffect(() => {
        if (typeof document === 'undefined') return;

        const isHomePage = location.pathname === `/${locale}/`;

        if (!document?.getElementById(scriptId)) {
            const script = document.createElement('script');
            script.id = scriptId;
            script.textContent = `
                (window._xsq||function(x,s){window._xsq=[];var c,b,a=document.createElement("iframe");a.src="javascript:false";a.title="";a.role="presentation";(a.frameElement||a).style.cssText="display: none";document.body.appendChild(a);try{b=a.contentWindow.document}catch(f){c=document.domain,a.src="javascript:var d=document.open();d.domain='"+c+"';void(0);",b=a.contentWindow.document}b.open()._l=function(){var a=this.createElement("script");c&&(this.domain=c);a.id="js-iframe-async";a.src="https://"+x+s;this.body.appendChild(a)};b.write('<body onload="document._l();">');b.close();return _xsq})("widgets.xsellco.com","/js/widgets.js").push(["load","9iyco79b2",document.scripts[document.scripts.length-1]]);
            `;

            document.body.appendChild(script);
        }

        const observer = new MutationObserver((mutationsList) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    const widgetInitFrame = document.querySelector('iframe#intercom-frame');

                    if (widgetInitFrame) {
                        hideOnHomepage();

                        observer.disconnect();
                        break;
                    }
                }
            }
        });

        observer.observe(document.body, { childList: true, subtree: true });

        function hideOnHomepage() {
            if (!isHomePage) {
                const widgetDivs = document.querySelectorAll('body > div[style*="z-index: 99999"], body > div[style*="z-index:99999"]');
                // Hide chat on mobile
                if (widgetDivs && widgetDivs.length > 0) widgetDivs.forEach(i => i.classList.add('hidden-md-down'));
            }
        }

        hideOnHomepage();

        return () => {
            observer.disconnect();
        }
    }, [location.pathname, locale]);

    return null;
}

export function showWidgetEdesk() {
    const widgetDivs = document.querySelectorAll('body > div[style*="z-index: 99999"], body > div[style*="z-index:99999"]');

    if (widgetDivs && widgetDivs.length > 0) {
        // Show chat on mobile
        widgetDivs.forEach(i => i.classList.remove('hidden-md-down'));

        // Non-documentary function for widget API
        // Used customer ID from main script on index.html

        // @ts-ignore
        if (window._xsq) {
            // @ts-ignore
            window._xsq.push([
                "showDefault",
                "9iyco79b2",
                document.getElementById(scriptId)
            ]);
        }
    } else {
        window.location.href = 'mailto:hello@lamafactory.com';
    }
}
