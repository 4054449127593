import React, { useState, useEffect } from "react";

import CContext, { cartItem } from "./cart-context";

const CContextProvider: React.FC = (props) => {
  const [cartItems, setCartItems] = useState<Array<cartItem>>(
    localStorage.getItem("userCart")
      ? JSON.parse(localStorage.getItem("userCart")!).cartItems !== undefined
        ? JSON.parse(localStorage.getItem("userCart")!).cartItems
        : []
      : []
  );
  //best is to create an interface

  const addItem = (article_id: string, quantity: Number) => {
    if (article_id !== "") {
      if (cartItems.length < 1) {
        setCartItems([
          {
            article_id: article_id,
            quantity: quantity,
          },
        ]);
      } else if (!cartItems.find((item) => item.article_id === article_id)) {
        setCartItems((cartItems) => [
          ...cartItems,
          { article_id: article_id, quantity: quantity },
        ]);
      }
    }
  };
  const addItems = (
    articles: Array<{ article_id: string; quantity: Number }>
  ) => {
    const items = [...cartItems];
    for (const article of articles) {
      if (!items.find((item) => item.article_id === article.article_id)) {
        items.push(article);
      }
    }
    setCartItems(items);
  };

  const removeItem = (article_id: string) => {
    setCartItems([
      ...cartItems.filter((item) => item.article_id !== article_id),
    ]);

    /*if(items.findIndex(item => item.article_id.toString() == article_id.toString()) != -1){
        console.log('test')
        setItems(items.filter(item=> item.article_id !==article_id));
    }*/
  };

  const changeQuantity = (article_id: string, quantity: Number) => {
    if (cartItems.find((item) => item.article_id === article_id)) {
      const new_items = cartItems;
      new_items[
        new_items.findIndex((item) => item.article_id === article_id)
      ].quantity = quantity;
      setCartItems(new_items);
    }

    localStorage.setItem(
      "userCart",
      JSON.stringify({
        cartItems: cartItems,
      })
    );
  };

  const clearCart = () => {
    setCartItems([]);
  };

  useEffect(() => {
    localStorage.setItem(
      "userCart",
      JSON.stringify({
        cartItems: cartItems,
      })
    );
  }, [cartItems]);

  return (
    <CContext.Provider
      value={{
        cartItems,
        addItem,
        addItems,
        removeItem,
        changeQuantity,
        clearCart,
      }}
    >
      {props.children}
    </CContext.Provider>
  );
};

export default CContextProvider;
